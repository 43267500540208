<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }} {{ privileges.name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }} {{ privileges.name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }} {{ privileges.name }}</h2>
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-else>
        <div class="card">
          <div class="card-header">
            <h4>Detail Privilege {{ privileges.name }}</h4>
          </div>
          <form @submit.prevent="PostData">
            <div class="card-body">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="select-all"
                  v-model="select_all"
                  @change="selectAll"
                />
                <label class="custom-control-label" for="select-all"
                  >Select All</label
                >
              </div>
              <div class="row mt-3">
                <div
                  class="col-md-4"
                  v-for="(privelege, index) in privileges.menu"
                  :key="index"
                >
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="privelege.id"
                      v-model="privelege.status"
                    />
                    <label class="custom-control-label" :for="privelege.id">{{
                      privelege.name
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button class="btn btn-primary mr-1" type="submit">
                Submit
              </button>
              <button class="btn btn-secondary" type="reset">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import swal from "sweetalert";
import { apiGet, apiPost } from "../../../services/api";

export default {
  name: "Privilege",
  data() {
    return {
      name: "Privilege",
      privileges: [],
      form: {
        role_id: "",
        menu_id: [],
      },
      select_all: false,
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    selectAll() {
      if (this.select_all == true) {
        for (let index = 0; index < this.privileges.menu.length; index++) {
          this.privileges.menu[index].status = true;
        }
      } else {
        for (let index = 0; index < this.privileges.menu.length; index++) {
          this.privileges.menu[index].status = false;
        }
      }
    },
    getData() {
      this.loading = true;
      let id = this.$route.params.id;
      apiGet("privilege/" + id).then((result) => {
        this.privileges = result.data.data;
        this.loading = false;
      });
    },
    PostData() {
      let formData = new FormData();
      formData.set("role_id", this.privileges.id);
      for (let index = 0; index < this.privileges.menu.length; index++) {
        if (this.privileges.menu[index].status == true) {
          formData.append("menu_id[]", this.privileges.menu[index].id);
        }
      }
      apiPost("privilege/update", formData)
        .then((res) => {
          this.$noty.success(res.data.data.message);
          this.$router.push({ name: "Privilege" });
        })
        .catch((err) => {
          swal("Error!", err.response.data.message, "error");
        });
    },
  },
};
</script>
